import React, { useEffect, useState, useRef } from "react"
import styled, { css } from "styled-components"
import IconExpand from "components/svg/iconExpand"

const StyledDropdown = styled.details`
  position: relative;
  display: inline-block;
  z-index: 10;
  ${props => props.minWidth && css`
    min-width: ${props.minWidth};
  `}
  user-select: none;
  summary {
    position: relative;
    z-index: 1;
    cursor: default;
    outline: none;
    height: 100%;
    list-style: none;
    div {
      height: 100%;
      font-size: 0.875em;
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      background-color: var(--color-white);
      ${props => props.theme === `medium` && css`
        background-color: rgba(170,170,170,0.2);
      `}
      border-radius: calc(2 * var(--border-radius));
      padding: 0.375em 0.625em;
      svg {
        margin-right: 0.5em;
      }
      ${props => props.iconToRight && css`
        flex-direction: row-reverse;
        justify-content: space-between;
        svg {
          margin-left: 0.5em;
          margin-right: 0;
        }
      `}
    }
    &::-webkit-details-marker {
      display: none;
    }
    .can-hover &:hover > div,
    &:active > div {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }

  > div {
    position: absolute;
    display: grid;
    grid-template: auto / 1fr;
    grid-gap: 0.25em;
    left: 0;
    background-color: rgba(255,255,255,0.8);
    backdrop-filter: var(--backdrop-filter);
    padding: 0.25em;
    box-shadow: 0 0.125em 0.75em -0.25em rgba(0,0,0,0.2);
    border-radius: var(--border-radius);
    min-width: 100%;
    button {
      display: block;
      font-size: 0.875em;
      font-weight: 500;
      padding: 0.25em 0.5em;
      white-space: nowrap;
      border-radius: var(--border-radius);
      text-align: left;
      .can-hover &:not(.isActive):hover,
      &:not(.isActive):active {
        background-color: var(--color-black);
        color: var(--color-white);
      }
      &.isActive {
        background-color: var(--color-gray);
      }
    }
  }
`

const Dropdown = ({
  label,
  children,
  iconToRight,
  minWidth,
  theme,
  className
}) => {

  const [opened, setOpened] = useState(false)

  const detailsRef = useRef()

  const handleToggle = (e) => {
    e.preventDefault()
    setOpened( ! opened )
  }
  const handleOuterClick = (e) => {
    if ( detailsRef.current && ! detailsRef.current.contains( e.target ) ) {
      setOpened( false )
    }
  }
  useEffect(() => {
    document.body.addEventListener('click',handleOuterClick, false)
    return () => {
      document.body.removeEventListener('click', handleOuterClick, false )
    }
  })
  
  return (
    <StyledDropdown 
      open={opened ? true : undefined}
      ref={detailsRef}
      iconToRight={iconToRight}
      minWidth={minWidth}
      theme={theme}
      className={className ? className : undefined}
    >
      <summary 
        onClick={(e) => handleToggle(e)}
      >
        <div><IconExpand />{label}</div>
      </summary>
      <div onClick={() => setOpened(false)}>
        {children}
      </div>
    </StyledDropdown>
  )
}

export default Dropdown
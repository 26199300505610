import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import MapRipple from "components/map/mapRipple"

const StyledMapPin = styled.b`
  position: absolute;
  display: block;
  z-index: ${props => props.countReleased > 0 ? `5` : `4`};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  width: 2em;
  text-align: center;
  border-radius: 1em;
  background-color: var(--color-gray-text);
  color: var(--color-white);
  top: ${props => props.top ? `${props.top}%` : `0`};
  left: ${props => props.left ? `${props.left}%` : `0`};
  transform: translate3d(-50%,-50%,0) scale(.4);
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: default;
  &[href] {
    cursor: pointer;
    background-color: var(--color-white);
    color: var(--color-black);
  }
  box-shadow: 0 0 0 3px rgba(0,0,0,0.3);
  &::before {
    content: ${props => props.count ? `"${props.count}"` : `"0"`};
    position: relative;
    display: block;
    font-size: 1.3em;
    font-weight: 700;
    color: currentColor;
    margin-top: 0.1em;
  }

  span {
    position: absolute;
    display: block;
    bottom: calc(100% + 0.25em);
    background-color: var(--color-black);
    color: var(--color-white);
    line-height: 1;
    padding: 0.375em 0.5em 0.325em;
    border-radius: 0.25em;
    font-size: 0.875em;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    left: 50%;
    transform: translate3d(-50%,0,0);
    opacity: 0;
    left: -9999em;
    white-space: nowrap;
    transition: opacity 0.3s ease, left 0s linear 0.3s;
    small {
      display: block;
      font-weight: 700;
      font-size: 0.625em;
      margin-top: 0.5em;
      letter-spacing: 0.075em;
    }
  }

  &:hover,
  &.is-active {
    z-index: 10;
    transform: translate3d(-50%,-50%,0) scale(1);
    &::before {
      display: block;
    }
    span {
        opacity: 1;
        left: 50%;
        transition: opacity 0.3s ease, left 0s linear;
    }
  }
  &:hover {
      z-index: 12;
  }
`


export { StyledMapPin }

const MapPin = ( props ) => {
  const releasedString = props.countReleased > 0 ? `${props.countReleased} released` : false
  const upcomingString = props.countUpcoming > 0 ? `${props.countUpcoming} upcoming` : false
  const releasesString = releasedString && upcomingString ? `${releasedString} | ${upcomingString}` : (
    releasedString ? releasedString : upcomingString
  )
  return (
    <>
      <MapRipple {...props} />
      <StyledMapPin 
        id={props.locationId}
        as={props.countReleased > 0 ? Link : `b`} 
        to={props.countReleased > 0 ? `/locations/${props.slug}` : null}
        {...props}
      >
        <span> {props.name} <small>{releasesString}</small></span>
      </StyledMapPin>
    </>
  )
}

export default MapPin
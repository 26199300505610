import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import useArtistData from "hooks/useArtistData"
import FiltersItem from "components/filters/filtersItem"



const FiltersIndex = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query {
        letters: allDirectusLetter(sort: {fields: label, order: ASC}) {
          edges {
              node {
                  id
                  directusId
                  name: label
                  slug
              }
          }
        }
      }
    `
  )

  const { artists } = useArtistData()

  return (
    <>
      {data.letters.edges.map(({ node }) => {
        const letter_id = node.id;
        let count = 0

        artists.edges.map(({ node }) => {
          if ( node.letter && node.letter.id === letter_id ) {
            count += 1;
          }
          return count
        })
        return (
          <FiltersItem
            to={`/artists/${node.slug}`}
            name={node.name}
            count={count}
          />
        )
      })}
    </>
  )
}

export default FiltersIndex

import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { useInViewport } from 'react-in-viewport';
import useArtistData from "hooks/useArtistData"
import MapPin from "components/map/mapPin"

const StyledMapWrap = styled.section`
    position: relative;
    z-index: 1;
    margin: 0;
    text-align: center;
`
const StyledMapBackground = styled.div`
  position: absolute;
`

const StyledMapContentWrap = styled.div`
  position: relative;
  max-width: none;
  @media ( max-width: 48em ) {
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
  }
`

const MapFigure = styled.figure`
  position: relative;
  display: block;
  margin: 0;
  img {
      position: relative;
      display: block;
      width: 100%;
      z-index: 1;
      opacity: 0.6;
  }
  @media ( max-width: 48em ) {
      width: 60.9333em !important;
      > .gatsby-image-wrapper {
          height: 100%;
          max-width: none !important;
      }
  }
`

const MapLocationWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media ( max-width: 48em ) {
    width: 60.9333em !important;
    max-width: none !important;
  }
`

const MapLocations = ({ 
  locations, 
  artists,
  hideMap,
  activeLocation
}) => {

  useEffect(() => {
    
    const shuffledLocations = locations.edges.sort(function() {
      return .5 - Math.random()
    })

    let index = 0
    const mapInterval = setInterval(() => {
      const activePin = document.querySelector('.map-pin.is-active')
      if ( activePin ) activePin.classList.remove('is-active')
      console.log(shuffledLocations[ index ].node.id)
      const newPin = document.getElementById( shuffledLocations[ index ].node.id )
      if ( newPin ) newPin.classList.add('is-active')

      index = index + 1 === shuffledLocations.length ? 0 : index + 1
      
    }, 1500)

    return function cleanup() {
      clearInterval(mapInterval)
    }
  })

  return (
    <>
      {locations.edges.map(({ node }) => {
        const location_id = node.id;
        let count_released = 0

        artists.edges.map(({ node }) => {
          if ( node.map_location && node.map_location.id === location_id ) {
            count_released += 1;
          }
          return count_released
        })
        const count_total = Number(node.count)
        const count_upcoming = count_total - count_released

        return (
          <MapPin
            locationId={node.id}
            top={node.top_coordinate}
            left={node.left_coordinate}
            count={count_total}
            countReleased={count_released}
            countUpcoming={count_upcoming}
            className="map-pin"
            title={node.name}
            name={node.name}
            slug={node.slug}
            activeLocation={activeLocation ? activeLocation : 0}
            onClick={(e) => hideMap && hideMap() }
          />
        )
      })}
    </>
  )

}

const Map = ( props ) => {
  const data = useStaticQuery(
    graphql`
      query {
        locations: allDirectusLocation(filter: {count: {gt: 0}}) {
          edges {
            node {
              id
              name
              slug
              top_coordinate
              left_coordinate
              count
            }
          }
        }
      }
    `
  )

  const { artists } = useArtistData()

  const mapRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    mapRef,
    {},
    { disconnectOnLeave: false },
    props
  );
  
  return (
    <StyledMapWrap>
      <StyledMapBackground />
        <StyledMapContentWrap>
          <MapFigure>
            {inViewport && <img src="/map-min.svg" alt="" />}
            <MapLocationWrap ref={mapRef}>
              {inViewport && 
                <MapLocations
                  locations={data.locations}
                  artists={artists}
                  hideMap={props.hideMap ? props.hideMap : undefined}
                  activeLocation={props.activeLocation ? props.activeLocation : undefined}
                />
              }
            </MapLocationWrap>
          </MapFigure>
        </StyledMapContentWrap>
    </StyledMapWrap>
  )
}

export { StyledMapWrap, StyledMapContentWrap }

export default Map
import React from "react"
import styled from 'styled-components'

const StyledIconExpand = styled.svg`
  height: 1.25em;
  width: 0.83333em;
  display: inline-block;
  vertical-align: middle;
  stroke: currentColor;
  margin-top: 0.0375em;
`

const IconExpand = () => (
  <StyledIconExpand viewBox="0 0 16 24" stroke="currentColor" fill="none" strokeWidth="2"><path d="M13 8.517L8 3 3 8.517M3 15.48l5 5.517 5-5.517"></path></StyledIconExpand>
)

export default IconExpand
import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"


const StyledFiltersItem = styled.a`
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8125em;
    font-weight: 500;
    padding: 0.5em 0.625em;
    line-height: 1;
    border-radius: calc( 2* var(--border-radius) );
    background-color: rgba(255,255,255,0.6);
    box-shadow: 0 0.125em 0.5em -0.25em rgba(0,0,0,0.2);
    @media ( min-width: 48em ) {
      font-size: 0.8125em;
    }
    i {
      display: inline-block;
      font-size: 0.75em;
      font-style: normal;
      background-color: rgba(170,170,170, 0.2);
      border-radius: var(--border-radius);
      padding: 0.29em 0.45em 0.25em 0.5em;
      line-height: 1;
      margin-top: 1px;
      margin-left: 0.75em;
      text-align: center;
      text-decoration: none !important;
    }
    .can-hover &:hover i,
    &:active i,
    &.is-active i {
      background-color: var(--color-gray-dark);
    }
    /*
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--color-black);
      border-radius: inherit;
      opacity: 0;
      transition: opacity 0.2s ease;
    }
    .can-hover &:hover::before,
    &:active::before,
    &.is-active::before {
      opacity: 0.3;
    }
    */
    .can-hover &:hover,
    &:active,
    &.is-active {
      background-color: var(--color-black);
      color: var(--color-white);
    }
    ${props => props.isStacked && css`
      flex-direction: column;
      align-items: center;
      i {
        margin-left: 0;
        margin-top: 0.5em;
      }
    `}
`


const FiltersItem = ( props ) => (
  <StyledFiltersItem
    as={Link}
    {...props}
    activeClassName="is-active"
  >
    <span>{props.name}</span>
    <i>{props.count}</i>
  </StyledFiltersItem>
)

export { StyledFiltersItem }

export default FiltersItem
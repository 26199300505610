import React from "react"
import styled, { css, keyframes } from "styled-components"

const pulse = keyframes`
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
`

const pulseLarge = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`

const StyledMapRipple = styled.span`
    position: absolute;
    display: block;
    z-index: 1;
    top: ${props => props.top ? `${props.top}%` : `0`};
    left: ${props => props.left ? `${props.left}%` : `0`};
    height: 12em;
    width: 12em;
    border-radius: 50%;
    ${props => props.count && css`
        transform: translate3d(-50%,-50%,0) scale( calc( 0.2 + ( (${props.count}*2/100) ) ) );
    `}
    color: #55A69F;
    transition: opacity 0.3s ease;
    span {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        box-shadow: inset 0 0 1em 3px currentColor;
        border: 1px solid currentColor;
        transform: scale(0.7);
        opacity: 0;
        animation: ${pulse} 4s ease-in-out infinite;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: currentColor;
            border-radius: inherit;
            opacity: 0.4;
        }
    }
    &:nth-of-type(2n) span {
        animation-delay: 2.625s;
    }
    &:nth-of-type(4n) span {
        animation-delay: 1.375s;
    }
    &.is-active {
        transform: translate3d(-50%,-50%,0) scale(1);
    }
    &.is-active span {
        animation: ${pulseLarge} 1.25s ease-in-out infinite;
    }
`

const MapRipple = ( props ) => (
  <StyledMapRipple {...props}>
    <span></span>
  </StyledMapRipple>
)

export default MapRipple
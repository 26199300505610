import { graphql, useStaticQuery } from "gatsby"

const useAlbumData = () => {
	const albumData = useStaticQuery(
		graphql`
			query {
        albums: allDirectusAlbum(filter: {wave: {in: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35"]}}, sort: {fields: temp_artist_sort_name, order: ASC}) {
          edges {
            node {
              id
              directusId
              cover {
                localFile {
                  childImageSharp {
										fluid(maxWidth:300) {
											...GatsbyImageSharpFluid_withWebp
										}
										large: fluid(maxWidth:700) {
											...GatsbyImageSharpFluid_withWebp
										}
                  }
                }
              }
              title
              genre {
                id
              }
              artist {
                name
                slug
                location
                map_location {
                  id
                }
                letter {
                  id
                }
              }
            }
          }
        }
			}
		`
	)
	return albumData
}

export default useAlbumData

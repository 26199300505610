import { graphql, useStaticQuery } from "gatsby"

const useArtistData = () => {
	const artistData = useStaticQuery(
		graphql`
			query {
				artists: allDirectusArtist(filter: {wave: {in: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35"]}}, sort: {order: ASC, fields: slug}) {
					edges {
						node {
							id
								name
								slug
								album_name
								location
								map_location {
									id
                }
                genre {
                  id
                }
                letter {
                  id
                }
								album_cover {
									localFile {
									childImageSharp {
										fluid(maxWidth:300) {
											...GatsbyImageSharpFluid_withWebp
										}
										large: fluid(maxWidth:700) {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
							}
						}
					}
				}
			}
		`
	)
	return artistData
}

export default useArtistData

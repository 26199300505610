import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import useAlbumData from "hooks/useAlbumData"
import FiltersItem from "components/filters/filtersItem"

const FiltersGenres = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query {
        genres: allDirectusGenre(sort: {order: ASC, fields: name}) {
          edges {
            node {
              id
              directusId
              slug
              name
            }
          }
        }
      }
    `
  )

  const { albums } = useAlbumData()

  return (
    <>
      {data.genres.edges.map(({ node }) => {
        const genre_id = node.id;
        let count = 0
      
        albums.edges.map(({ node }) => {
          if ( node.genre[0] && node.genre[0].id === genre_id ) {
            count += 1;
          }
          if ( node.genre[1] && node.genre[1].id === genre_id ) {
            count += 1;
          }
          return count
        })
        return (
          <FiltersItem
            to={`/genres/${node.slug}`}
            name={node.name}
            count={count}
          />
        )
      })}
    </>
  )
}

export default FiltersGenres

import React, { useState } from "react"
import styled from "styled-components"
import Media from "react-media"
import Dropdown from "components/ui/dropdown"
import FiltersGenres from "components/filters/filtersGenres"
import FiltersIndex from "components/filters/filtersIndex"

const StyledFilters = styled.div`
  position: sticky;
  z-index: 50;
  top: 4.125em;
  padding: 0.75em 0;
  display: flex;
  user-select: none;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: var(--color-white);
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
  }
  .hasScrolled &::after {
    opacity: 0;
  }
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: rgba(255,255,255,0.925);
    backdrop-filter: var(--backdrop-filter);
    backdrop-filter: blur(8px) saturate(4) contrast(4);
  }
  .hasScrolled &::before {
    box-shadow: 0 0.125em 0.25em rgba(0,0,0,0.05);
  }
  @media ( min-width: 54em ) {
    top: 3.3125em;
  }
  @media ( min-width: 60em ) {
    .show-player &::before,
    .show-player &::after {
      width: calc(100vw - (200px + 3em));
      left: -3em;
      margin-left: 0;
    }
  }
  @media ( min-width: 105em ) {
    top: 3.25em;
  }
`

const StyledFiltersToggles = styled.div`
  display: flex;
  @media ( max-width: 36em ) {
    justify-content: space-between;
  }
  @media ( min-width: 36em ) {
    justify-content: flex-start;
  }
`

const StyledFilterToggle = styled.button`
  appearance: none;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  padding: 0.5em 0.875em;
  cursor: default;
  white-space: nowrap;
  background-color: rgba(170,170,170,0.2);
  .can-hover &:not(.is-active):hover,
  &:not(.is-active):active {
    background-color: var(--color-gray);
    color: currentColor;
  }
  &.is-active {
    background-color: var(--color-black);
    color: var(--color-white);
  }
  &:first-of-type {
    border-top-left-radius: calc(2* var(--border-radius));
    border-bottom-left-radius: calc(2* var(--border-radius));
  }
  &:last-of-type {
    border-top-right-radius: calc(2* var(--border-radius));
    border-bottom-right-radius: calc(2* var(--border-radius));
  }
`

const StyledFiltersSet = styled.nav`
  display: flex;
  margin: 0 0 0 0.5em;
  background-color: rgba(170,170,170,0.2);
  padding: 0.25em 0;
  border-radius: calc(2* var(--border-radius));
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  user-select: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::after,
  &::before {
    content: '';
    position: relative;
    display: block;
    padding: 0 0.125em;
  }
  a:not(:last-of-type) {
    margin-right: 0.5em;
  }
  @media ( max-width: 48em ) {
    margin-right: -6vw;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media ( min-width: 48em ) {
    margin: 0 1em;
  }
`


const Filters = props => {
  
  const [tab, setTab] = useState((props.filterTab ? props.filterTab : `genres`))

  function toggleTab( newState ) {
    ( tab !== newState ) ? setTab( newState ) : setTab( tab )
    if ( ! props.hideMap ) {
      if ( newState === `map` && ! props.showMap ) {
        props.setShowMap(true)
      }
      else {
        props.setShowMap(false)
      }
    }
  }

  const labels = {
    genres: `Genres`,
    index: `A-Z`
  }

  return (
    <StyledFilters>
      <Dropdown
        label={labels[tab]}
        minWidth="5.375em"
        theme="medium"
        className="isMobileOnly"
      >
        <button 
          onClick={(e) => toggleTab(`genres`)} 
          className={tab === `genres` ? `is-active` : undefined}
        >Genres</button>
        <button 
          onClick={(e) => toggleTab(`index`)} 
          className={tab === `index` ? `is-active` : undefined}
        >Artists A-Z</button>
      </Dropdown>
      <StyledFiltersToggles
        className="isNonMobileOnly"
      >
        <StyledFilterToggle 
          onClick={(e) => toggleTab(`genres`)} 
          className={tab === `genres` ? `is-active` : undefined}
        >Genres</StyledFilterToggle>
        <StyledFilterToggle 
          onClick={(e) => toggleTab(`index`)} 
          className={tab === `index` ? `is-active` : undefined}
        >Artists A-Z</StyledFilterToggle>
      </StyledFiltersToggles>
      <StyledFiltersSet>
        {tab === `index` ?
          <FiltersIndex />
          :
          <FiltersGenres />
        }
      </StyledFiltersSet>
      {! props.hideMap &&
        <StyledFiltersToggles
          className="isNonMobileOnly"
        >
          <StyledFilterToggle 
            onClick={(e) => toggleTab(`map`) } 
            className={tab === `map` ? `is-active` : undefined}
          >Explore the Map</StyledFilterToggle>
        </StyledFiltersToggles>
      }
    </StyledFilters>
  )
}

export default Filters